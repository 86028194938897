import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import './styles/App.css';
import NotFound from './pages/NotFound';

const App: React.FC = () => {
	return (
		<div className='app'>
			<header>
				<nav>
					<ul>
						<li><Link to='/'>Home</Link></li>
						<li><Link to='/projects'>Projects</Link></li>
						<li><Link to='/contact'>Contact</Link></li>
					</ul>
				</nav>
			</header>
			<div className='content-wrapper'>
				<main>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/projects' element={<Projects />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='*' element={<NotFound />} />
					</Routes>
				</main>
				{/* <aside className='company-info'>
						<h2>About Us</h2>
						<p><strong>Name:</strong> Abbeydale Machine & Tool Co Ltd</p>
						<p><strong>Phone Number:</strong> 0121 785 2297</p>
						<p><strong>Email:</strong> info@abbeydalemachineandtool.co.uk</p>
						<p><strong>Address:</strong></p>
						<p>Abbeydale Machine and Tool Co. Ltd.</p>
						<p>Unit 16 Stechford Trading Estate</p>
						<p>Lyndon Road</p>
						<p>Stechford</p>
						<p>B33 8BU</p>
					</aside> */}
			</div>
			<footer>
				<p>&copy; Jack 2020-2024</p>
			</footer>
		</div>
	);
};

export default App;
