import React from 'react';
import { FaDiscord, FaGithub } from 'react-icons/fa';
import '../styles/Projects.css';

const ProjectCard: React.FC<{
  title: string;
  description: string;
  sourceAvailability: string;
  website?: string;
  language: string;
  supportLink?: string;
  githubLink: string;
  members?: { name: string, profile: string }[];
  inviteLink?: string;
  logoSrc?: string;
}> = ({
	title,
	description,
	sourceAvailability,
	website,
	language,
	supportLink,
	githubLink,
	members,
	inviteLink,
	logoSrc,
}) => {
	return (
		<div className='project-card'>
			<div className='project-header'>
				<h3>{title}</h3>
				{logoSrc && <img src={logoSrc} alt={`${title} logo`} className='project-logo' />}
			</div>
			<p className='project-description'>{description}</p>
			<p><strong>Language:</strong> {language}</p>
			{members && members.length > 0 && (
				<p><strong>Organisation Members: </strong>
					{members.map((member, index) => (
						<React.Fragment key={member.name}>
							<a href={member.profile} target='_blank' rel='noopener noreferrer'>
								{member.name}
							</a>
							{index < members.length - 1 && ' | '}
						</React.Fragment>
					))}
				</p>
			)}
			<p><strong>Source:</strong> {sourceAvailability}</p>
			{website && (
				<p><strong>Website:</strong> <a href={website} target='_blank' rel='noopener noreferrer'>{website}</a></p>
			)}
			<p><strong>GitHub:</strong> {githubLink ? <a href={githubLink} target='_blank' rel='noopener noreferrer'><FaGithub /> View on GitHub</a> : 'Private'}</p>
			<p><strong>Discord:</strong> {supportLink ? <a href={supportLink} target='_blank' rel='noopener noreferrer'><FaDiscord /> Get Support</a> : 'None'}</p>
			{inviteLink && (
				<p><strong>Invite Link:</strong> <a href={inviteLink} target='_blank' rel='noopener noreferrer'>Get the bot</a></p>
			)}
		</div>
	);
};

const Projects: React.FC = () => {
	return (
		<div className='projects'>
			<h2>My Projects</h2>
			<div className='project-list'>
				<ProjectCard
					title='Neon'
					description='A multi-purpose Discord Bot serving around 1M users across 4000+ servers.'
					website='https://neonbot.xyz'
					sourceAvailability='Private'
					language='TypeScript (Node.js)'
					supportLink='https://neonbot.xyz/support'
					githubLink='https://github.com/neonbot-dev/neonbot'
					members={[
						{ name: 'Jack', profile: 'https://github.com/wik3d' },
						{ name: 'SpoopyTim', profile: 'https://github.com/spoopytim' },
						{ name: 'nxsi', profile: 'https://github.com/n-xsi' },
					]}
					inviteLink='https://neonbot.xyz/invite'
					logoSrc='neon2.png'
				/>
				<ProjectCard
					title='Reddit Extractor'
					description='An effective Reddit post fetcher, bypassing JSON API restrictions with proxies and randomised cookies'
					sourceAvailability='Public'
					language='TypeScript'
					supportLink={undefined}
					githubLink='https://github.com/wik3d/Reddit-Scraper'
					members={[]}
					inviteLink={undefined}
					logoSrc='RedditLogo.jpeg'
				/>
				{/* Add more <ProjectCard /> components here for additional projects */}
			</div>
		</div>
	);
};

export default Projects;
