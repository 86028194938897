import React from 'react';
import {
	SiTypescript, SiHtml5, SiCss3, SiJavascript, SiNodedotjs,
	SiPython, SiDocker, SiCloudflare, SiGithub, SiUbuntu,
	SiArchlinux, SiVisualstudiocode, SiReact, SiLua,
} from 'react-icons/si';
import '../styles/Home.css';

const Home: React.FC = () => {
	return (
		<div className='home'>
			{/* First Section */}
			<section className='intro-section'>
				<h1>Hello, My name is Jack</h1>
				<p>I'm a passionate developer and love working with everything computers.</p>
				<p>Things I like to work with:</p>
			</section>

			{/* Second Section */}
			<section className='skills-section'>
				<div className='skills-grid'>
					<div className='icon-text'>
						<SiTypescript />
						<p>TypeScript</p>
					</div>
					<div className='icon-text'>
						<SiHtml5 />
						<p>HTML</p>
					</div>
					<div className='icon-text'>
						<SiCss3 />
						<p>CSS</p>
					</div>
					<div className='icon-text'>
						<SiJavascript />
						<p>JavaScript</p>
					</div>
					<div className='icon-text'>
						<SiNodedotjs />
						<p>Node.js</p>
					</div>
					<div className='icon-text'>
						<SiPython />
						<p>Python</p>
					</div>
					<div className='icon-text'>
						<SiLua />
						<p>Lua</p>
					</div>
					<div className='icon-text'>
						<SiDocker />
						<p>Docker</p>
					</div>
					<div className='icon-text'>
						<SiCloudflare />
						<p>Cloudflare</p>
					</div>
					<div className='icon-text'>
						<SiGithub />
						<p>GitHub</p>
					</div>
					<div className='icon-text'>
						<SiUbuntu />
						<p>Ubuntu</p>
					</div>
					<div className='icon-text'>
						<SiArchlinux />
						<p>Arch Linux</p>
					</div>
					<div className='icon-text'>
						<SiVisualstudiocode />
						<p>VSCode</p>
					</div>
					<div className='icon-text'>
						<SiReact />
						<p>React</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Home;
