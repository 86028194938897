import React from 'react';
import '../styles/Contact.css';

const Contact: React.FC = () => {
	return (
		<div className='contact'>
			<div className='contact-card'>
				<div className='contact-banner'>
					<img src='banner.png' alt='Discord Banner' />
				</div>
				<div className='contact-profile'>
					<img src='pfp.png' alt='Profile' className='profile-picture' />
					<h3>Jack</h3>
					<p><strong>Email:</strong> jackthedevbusiness@gmail.com</p>
					<p><strong>Discord Username:</strong> .jac.k.</p>
					<p><strong>Discord User ID:</strong> 594937666168946700</p>
				</div>
			</div>
		</div>
	);
};

export default Contact;